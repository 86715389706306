import { h, Fragment } from "preact";

import { renderSwitcher } from "../../shared/utils";

import {
  useLanguageUrls,
  useDropdown,
  useResponsiveDisplay,
  useStyleTag,
} from "../../shared/hooks";

import Option from "./Option";
import createStyle from "./style.js";

const switcherName = "skewed";

const Switcher = ({ style, opts, colors }) => {
  const {
    opensUpward,
    open,
    opensLeftward,
    language,
    otherLanguages,
    focusedLanguage,
    switcherContainerRef,
    focusedLanguageRef,
    handleMouseEnter,
    handleMouseLeave,
    handleKeyDown,
    switchLanguage,
    toggleOpen,
  } = useDropdown(opts);
  const urls = useLanguageUrls();
  const responsiveVisible = useResponsiveDisplay(opts);
  const hasStyleTag = useStyleTag({ style, colors }, createStyle, switcherName);

  const verticalClass = opensUpward ? "wg-up" : "wg-down";
  const horizontalClass = opensLeftward ? "wg-left" : "wg-right";
  const closedClass = !open ? " closed" : "";

  if (!responsiveVisible || !otherLanguages.length) {
    return <Fragment />;
  }

  return (
    <div
      ref={switcherContainerRef}
      onKeyDown={handleKeyDown}
      {...(!hasStyleTag && { style: { display: "none" } })}
      onMouseLeave={handleMouseLeave}
      data-wg-notranslate
      {...(!opts.open_hover && { onClick: toggleOpen })}
      aria-expanded={open}
      aria-label="Language Switcher"
      aria-controls="language-list"
      tabIndex={0}
      role="combobox"
      className={`weglot_switcher ${switcherName}${closedClass} ${verticalClass} ${horizontalClass}`}>
      <Option
        styleOpts={style}
        colors={colors}
        switcherOpen={open}
        onMouseEnter={handleMouseEnter}
        language={language}
        active={true}
        url="#"
      />
      <div className="language-list" role="listbox">
        {otherLanguages.map(l => {
          return (
            <Option
              language={l}
              url={urls[l]}
              colors={colors}
              switcherOpen={open}
              onClick={switchLanguage}
              active={false}
              key={`wg-${l}`}
              tabIndex={open ? 0 : -1}
              isFocused={l === focusedLanguage}
              focusRef={focusedLanguageRef}
              styleOpts={style}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Switcher;

export const createSwitcher = renderSwitcher(Switcher, switcherName);
