import { resize } from "../../shared/utils";
function getCss({ style: { size_scale }, colors }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: "",
      declarations: {
        position: "relative",
      },
    },
    {
      selector: ".closed",
      declarations: {
        backgroundColor: colors.bg_active,
        borderRadius: `${scale(5)}px`,
      },
    },
    {
      selector: ".closed .language-list",
      declarations: {
        opacity: "0",
        pointerEvents: "none",
      },
    },
    {
      selector: ".closed.wg-left.wg-up .language-list",
      declarations: {
        transform: "skew(10deg)",
      },
    },
    {
      selector: ".closed.wg-right.wg-down .language-list",
      declarations: {
        transform: "skew(10deg)",
      },
    },
    {
      selector: ".closed.wg-right.wg-up .language-list",
      declarations: {
        transform: "skew(-10deg)",
      },
    },
    {
      selector: ".closed.wg-left.wg-down .language-list",
      declarations: {
        transform: "skew(-10deg)",
      },
    },
    {
      selector: " .language-list",
      declarations: {
        position: "absolute",
        opacity: "0.99",
        borderRadius: `${scale(5)}px`,
        transition: "transform 0.2s ease-in-out, opacity 0.2s ease-in-out",
        backgroundColor: colors.bg_inactive,
        display: "flex",
        justifyContent: "center",
        width: "max-content",
      },
    },
    {
      selector: ".wg-up .language-list",
      declarations: {
        bottom: "0",
        top: "auto",
        flexDirection: "column-reverse",
        transformOrigin: "0% 100%",
        paddingBottom: `${scale(40)}px`,
      },
    },
    {
      selector: ".wg-down .language-list",
      declarations: {
        bottom: "auto",
        top: `0`,
        flexDirection: "column",
        transformOrigin: "100% 0%",
        paddingTop: `${scale(40)}px`,
      },
    },
    {
      selector: " .active-language",
      declarations: {
        zIndex: 2147483646,
        position: "relative",
        opacity: 1,
      },
    },
    {
      selector: " .language-option",
      declarations: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        fontSize: `${scale(14)}px`,
        height: `${scale(40)}px`,
        padding: `0px ${scale(15)}px`,
      },
    },
    {
      selector: " .language-option a",
      declarations: {
        outline: "none",
        fontWeight: 700,
        textDecoration: "none",
        display: "flex",
        gap: `${scale(10)}px`,
        alignItems: "center",
      },
    },
    {
      selector: " .language-option a img",
      declarations: {
        overflowClipMargin: "content-box",
        overflow: "clip",
        verticalAlign: "middle",
        objectFit: "cover",
        display: "inline-block",
        border: "0",
        width: `${scale(20)}px`,
        height: `${scale(16)}px`,
      },
    },
  ];
}

export default getCss;
